import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useTagManager = (data = {}) => {
  const router = useRouter();
  const path = router.asPath.split('?')[0];
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'google-analytics';
    script.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(${JSON.stringify(data)});
        `;

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
};
