import React, { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export type LinkType = 'primary' | 'secondary' | 'bold' | 'capital' | 'tag' | 'dark';

export interface LinkProps extends React.ComponentProps<typeof NextLink>, PropsWithChildren, PropsWithClassProps {
  noLink?: boolean;
  type?: LinkType;
  useNextLink?: boolean;
  className?: string;
  title?: string;
  target?: string;
  ariaLabel?: string;
  rel?: string;
}

export const Link = ({
  children,
  type,
  href,
  title,
  target,
  rel,
  ariaLabel,
  noLink,
  useNextLink,
  className,
  classProps,
  ...others
}: LinkProps) => {
  const typeClasses = {
    primary: 'text-primary hover:underline',
    secondary: 'font-bold text-xl hover:underline text-secondary',
    bold: 'text-primary font-bold text-ml hover:underline',
    capital: 'text-primary uppercase text-3xs hover:underline',
    dark: 'text-white text-sm hover:underline',
    tag: 'text-primary inline-block rounded-full bg-gray-200 px-8 py-4 text-3xs hover:bg-gray-500',
  };

  const linkStyle = twMerge(typeClasses?.[type] || null, className, classProps?.root);

  if (noLink) {
    return <span className={linkStyle}>{children}</span>;
  }

  return useNextLink ? (
    <NextLink href={href} {...others} prefetch={false} className={linkStyle} target={target} rel={rel}>
      {children}
    </NextLink>
  ) : (
    <a
      {...others}
      href={typeof href == 'string' ? href : '/'}
      className={linkStyle}
      title={title}
      aria-label={ariaLabel}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};
